body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#error-page {
  padding: 20px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 10px rgba(255, 241, 118, 0.7);
  }
  50% {
    box-shadow: 0 0 10px rgba(255, 241, 118, 0.9), 0 0 15px rgba(255, 241, 118, 0.7);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 241, 118, 0.7);
  }
}

.pulse {
  border: 1px solid rgba(255, 241, 118, 0.7);
  animation: pulse 2s infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  } to {
    opacity: 0;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  opacity: 1;
}

.fade-in-slow {
  animation-name: fadeIn;
  animation-duration: 1s;
  opacity: 1;
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.5s;
  opacity: 0;
}